export const unit = 8

// const sans = `-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif`
// export const fonts = {
//   sans,
//   mono: `'apercu-mono-pro',monospace`,
//   brand: `'apercu-pro','Apercu Pro',${sans}`,
// }

export const fontFamily = {
    sourceSans:
        'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,400;0,700;0,900;1,400&display=swap',
}

export const fontWeights = {
    light: 200,
    regular: 400,
    semibold: 700,
    bold: 900,
    inherit: 'inherit',
}

export const screenSizes = {
    xl: 1440,
    lg: 1024,
    md: 768,
    sm: 414,
}

// export const areas = {
//   xsmall: 8,
//   small: 16,
//   medium: 32,
//   large: 48,
//   xlarge: 64,
// }

// export const fontSizes = {
//   size7: 80,
//   size6: 64,
//   size5: 48,
//   size4: 36,
//   size3: 24,
//   size2: 20,
//   size1: 16,
//   size0: 14,
//   sizeN1: 12,
//   sizeN2: 10,
// }

// export const lineHeights = {
//   size7: 96,
//   size6: 76,
//   size5: 56,
//   size4: 44,
//   size3: 32,
//   size2: 28,
//   size1: 24,
//   size0: 20,
//   sizeN1: 18,
//   sizeN2: 16,
// }

// export const textSizes = {
//   size7: { fontSize: fontSizes.size7, lineHeight: lineHeights.size7 },
//   size6: { fontSize: fontSizes.size6, lineHeight: lineHeights.size6 },
//   size5: { fontSize: fontSizes.size5, lineHeight: lineHeights.size5 },
//   size4: { fontSize: fontSizes.size4, lineHeight: lineHeights.size4 },
//   size3: { fontSize: fontSizes.size3, lineHeight: lineHeights.size3 },
//   size2: { fontSize: fontSizes.size2, lineHeight: lineHeights.size2 },
//   size1: { fontSize: fontSizes.size1, lineHeight: lineHeights.size1 },
//   size0: { fontSize: fontSizes.size0, lineHeight: lineHeights.size0 },
//   sizeN1: { fontSize: fontSizes.sizeN1, lineHeight: lineHeights.sizeN1 },
//   sizeN2: { fontSize: fontSizes.sizeN2, lineHeight: lineHeights.sizeN2 },
// }

// export const shadows = {
//   shadow1: '0 2px 4px rgba(0,0,0,.08)',
//   shadow2: '0 2px 4px rgba(0,0,0,.08),0 2px 12px rgba(0,0,0,.06)',
//   shadow3:
//     '0 2px 4px rgba(0,0,0,.08),0 2px 12px rgba(0,0,0,.06),0 8px 14px rgba(0,0,0,.04),0 12px 16px rgba(0,0,0,.02)',
// }

// export const spaceFrames = {
//   small: [16, 16],
//   medium: [32, 32],
// }

// export const borderRadii = {
//   small: 4,
//   medium: 8,
//   large: 16,
//   circle: 500
// }

// export const greyscaleColors = {
//   white: '#FFF',
//   grey0: '#FAFAFA',
//   grey1: '#F0F0F0',
//   grey2: '#E3E3E3',
//   grey3: '#D3D3D3',
//   grey10: '#c4c4c4',
//   grey4: '#BBB',
//   grey5: '#AAA',
//   grey6: '#898989',
//   grey7: '#737373',
//   grey8: '#4c4c4c',
//   grey11: '#333333',
//   grey9: '#262627',
//   black: '#000',
//
//   blackFaded1: 'rgba(0,0,0,.07)',
// }

// export const baseColors = {
//   deYork: '#7DBB91',
//   tradewind: '#4FB0AE',
//   roman: '#D95767',
//
//   emerald: '#3FC46A',
//   pineGlade: '#BBD396',
//   genoa: '#377B7A',
//   hippieBlue: '#4fa9b3',
//   hippiePink: '#AE4652',
//   yellowOrange: '#ffba49',
//   tulipTree: '#e4b83a',
//   terracotta: '#E26d5a',
//   sage: '#94ae89',
//   eastSide: '#a086c4',
//   nightShadz: '#bf395b',
//   ming: '#3a7685',
//   glacier: '#74a4bf',
//   creamCan: '#f3cd59',
//   seaPink: '#f09f97',
//   cranberry: '#e04f78',
//   eastBay: '#58457a',
//   brandyRose: '#bf9677',
//   sanMarino: '#3C6997',
//   goblin: '#457A53',
//   wildWillow: '#bac26c',
//   valencia: '#d94c4c',
//   morningGlory: '#96CEDC',
//   blueSky: '#398EEA',
//   electricBlue: '#2E95F5',
// }

// export const brandColors = {
//   charcoal: greyscaleColors.grey9,
//   stone0: '#FCFAF8',
//   stone1: '#F8F6F1',
//   stone2: '#F5F1EA',
//   stone3: '#F1ECE3',
// }

// export const appSpecific = {
//   primary: baseColors.tradewind,
//   body: greyscaleColors.grey9,
//   warning: baseColors.creamCan,
//   highlight: baseColors.blueSky,
//   danger: baseColors.roman,
//   border: greyscaleColors.blackFaded1,
//   default: greyscaleColors.blackFaded1,
//   pro: baseColors.deYork,
//   proplus: baseColors.tulipTree,
//   backdrop: 'rgba(38,38,39,0.8)',
// }

// export const colors = Object.assign(
//   {},
//   greyscaleColors,
//   baseColors,
//   appSpecific
// )

// export const darkModeColors = {
//   controls: {
//     enabled: {
//       backgroundColor: 'transparent',
//       borderColor: '#ffffff99',
//       color: '#ffffffde',
//       placeholder: '#ffffff99',
//     },
//     disabled: {
//       backgroundColor: '#ffffff1f',
//       borderColor: '#ffffff1f',
//       color: '#ffffff61',
//       placeholder: '#ffffff99',
//     },
//     hovered: {
//       backgroundColor: '#ffffff1f',
//       borderColor: '#ffffff99',
//       color: '#ffffffde',
//       placeholder: '#ffffff99',
//     },
//     focused: {
//       backgroundColor: '#ffffff1f',
//       borderColor: '#ffffff',
//       color: '#ffffffde',
//       placeholder: '#ffffff99',
//     },
//     danger: {
//       backgroundColor: '#ffffff1f',
//       borderColor: colors.roman,
//       color: '#ffffffde',
//       placeholder: '#ffffff99',
//     },
//     success: {
//       backgroundColor: '#ffffff1f',
//       borderColor: colors.tradewind,
//       color: '#ffffffde',
//       placeholder: '#ffffff99',
//     },
//   },
//   RichContent: {
//     multipleChoice: '#E040FB',
//     pictureChoice: '#EA80FC',
//     yesNo: '#B388FF',
//     dropdown: '#8C9EFF',
//     legal: '#AAB6FE',
//     shortText: '#1E88E5',
//     longText: '#64B5F6',
//     email: '#81D4FA',
//     website: '#B3E5FC',
//     rating: '#FFD600',
//     opinionScale: '#FFC107',
//     statement: '#FFA000',
//     payment: '#FF8A65',
//     fileUpload: '#FFAB91',
//     number: '#D4E157',
//     date: '#AEEA00',
//     phoneNumber: '#64DD17',
//     welcomeScreen: '#52C7B8',
//     ending: '#1DE9B6',
//   },
//   backgroundColor: '#141C24',
//   borderColor: '#fff',
//   color: '#fff',
// }

// export const fontSans = fonts.sans
// export const fontMono = fonts.mono
// export const widths = areas
//
// export const dimmed = 0.8
//
// export const animationDelay = 200

// export default {
//   fontFamily,
// }
// export const getDarkColor = (props, type) => {
//   if (props.errorMessage) return darkModeColors.controls.danger[type]
//   if (props.successMessage) return darkModeColors.controls.success[type]
//   if (props.disabled) return darkModeColors.controls.disabled[type]
//   if (props.focused) return darkModeColors.controls.focused[type]
//   if (props.hovered) return darkModeColors.controls.hovered[type]
//   if (props.selected) return darkModeColors.controls.focused[type]
//   return darkModeColors.controls.enabled[type]
// }
